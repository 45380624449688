import Button from "components/general/buttonv2";
import Link from "next/link";
import styles from "./homeSectionTwo.module.css";

const data = [
  {
    key: "discord",
    title: "Discord",
    description:
      "Cleanse your cringe-worthy Discord history by either nuking it all, choosing a specific server or direct message, and then specifying keywords and date ranges.",
    color: "#6679d3",
  },
  {
    key: "twitter",
    title: "Twitter",
    description:
      "Delete old tweets, pictures, and videos for rebranding, spring cleaning, or just plain peace of mind.",
    color: "#40aadb",
  },
  {
    key: "reddit",
    title: "Reddit",
    description:
      "Remove old Reddit posts and comments to get rid of any old skeletons in your post history that are costing you karma.",
    color: "#ff6538",
  },
  {
    key: "facebook",
    title: "Facebook",
    description: "Delete anything that you posted either on your own profile or on someone else's.",
    color: "#2d86e5",
  },
];

const Grid = () => {
  return (
    <div className={styles.grid}>
      {data.map((value) => (
        <div className={`${styles.grid_item__wrapper}`} key={value.title}>
          <Link href={`/services/${value.key}`} passHref className={styles.grid_item}>
            <div
              className={styles.grid_item__border}
              style={{ background: value.color || "var(--text-color)" }}
            />
            <div className={styles.grid_item__content}>
              <div className={styles.grid_item__title}>
                <h3>{value.title}</h3>
              </div>
              <div className={styles.grid_item__body}>{value.description}</div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

const HomeSectionTwo = () => {
  return (
    <section className={styles.wrapper} style={{ background: "var(--background-color)" }}>
      <div className={styles.container}>
        <h2>Services We Support</h2>
        <p>Here is the brief overview of what services we currently support</p>

        <div className={styles.content_wrapper}>
          <div style={{ flex: 2 }}>
            <Grid />
            <div className={`${styles.grid_item__wrapper}`}>
              <Link href="/services" passHref className={styles.grid_item}>
                <div className={styles.grid_item__content_add}>
                  <svg
                    width="47"
                    height="48"
                    viewBox="0 0 47 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.775391"
                      width="20.7839"
                      height="20.7839"
                      rx="2.36181"
                      fill="#B8C3E9"
                    />
                    <rect
                      y="26.9922"
                      width="20.7839"
                      height="20.7839"
                      rx="2.36181"
                      fill="#6C8EF8"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M35.6132 26.9922C33.9692 26.9922 32.6365 28.3249 32.6365 29.9689V34.4079L28.1984 34.4079C26.5544 34.4079 25.2217 35.7406 25.2217 37.3846C25.2217 39.0286 26.5544 40.3613 28.1984 40.3613H32.6365V44.7994C32.6365 46.4434 33.9692 47.7761 35.6132 47.7761C37.2572 47.7761 38.5899 46.4434 38.5899 44.7994V40.3613H43.0289C44.6729 40.3613 46.0056 39.0286 46.0056 37.3846C46.0056 35.7406 44.6729 34.4079 43.0289 34.4079L38.5899 34.4079V29.9689C38.5899 28.3249 37.2572 26.9922 35.6132 26.9922Z"
                      fill="#B8C3E9"
                    />
                    <rect
                      x="25.2227"
                      y="0.775391"
                      width="20.7839"
                      height="20.7839"
                      rx="2.36181"
                      fill="#6C8EF8"
                    />
                  </svg>
                  <div>
                    <div className={styles.grid_item__title}>
                      <h3>Delete from 30+ services</h3>
                    </div>
                    <div className={styles.grid_item__body}>
                      Redact supports more than 40 services; delete your old data from all of your
                      most-used services including Discord, Twitter, Facebook, and much more!
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src="/images/svgs/redacting.svg"
              alt="Redact from over 40 services"
              className={styles.content_wrapper_image}
            />
          </div>
        </div>

        <div className={styles.banner__wrapper}>
          <div className={styles.banner} style={{ background: "var(--raised-background-color)" }}>
            {/*eslint-disable-next-line @next/next/no-img-element*/}
            <img src="/images/appicon.png" alt="redact" height={39} width={39} />
            <div className={styles.banner__text}>
              {`Redact supports all of the most popular services for Social Media and Direct Messaging,
              and we're always looking to expand our supported services.`}
            </div>
            <div className={styles.button}>
              <Button to="/services" buttonPrimary={true} alignSelf="center">
                View All Services
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSectionTwo;
